import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

export function ArticleMeta({ article }) {
  const { author, frontmatter, timeToRead } = article
  return (
    <div className="post-meta flex items-center mt-3">
      <div className="post-meta-left">
        <div className="flex -space-x-2 overflow-hidden mr-3">
          <Link className="post-author-image-link h-8 w-8" to={author.url}>
            <StaticImage className="inline-block h-8 w-8 rounded-full ring-2 ring-white" 
              src="../../images/author/shailesh.jpg" width={32} height={32} 
              alt="Shailesh Pandit profile picture" title="Shailesh Pandit profile picture linked to about section of DopeAI">
            </StaticImage>
          </Link>
        </div>
      </div>
      <div className="post-meta-right">
        <div className="post-author-name text-sm">
          <Link className="text-gray-800 post-author-name-link" to={author.url}>{author.name}</Link>
        </div>
        <div className="post-meta-bottom flex text-sm text-gray-500">
          <div className="post-date">
            <time dateTime="2019-05-31">
              {frontmatter.date}
            </time>
          </div>
          <div className="px-2">.</div>
          <div className="post-length">{timeToRead} min read</div>
        </div>
      </div>
    </div>
  )
}