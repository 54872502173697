import * as React from "react"
import { Link } from "gatsby"

function Banner({ topClass, message }) {
    return (
        <div className={`sticky ${topClass || 'top-0'} bg-opacity-95 z-10 bg-blue-600 text-white flex items-center justify-center`}>
        <p className="text-sm tracking-widest text-center px-4 py-3">
          <span className="uppercase">{message || (<>Save upto <b>58%</b> on <b>OptiCull Pro</b> lifetime (1st Anniversary Sale, limited seats)</>)}</span>
          <Link to="/opticull">
            <button className="ml-3 bg-gray-800 px-3 py-1 rounded text-white hover:text-gray-200" role="link">
              Learn More
            </button>
          </Link>
        </p>
      </div>
    )
}

export default Banner