import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Hero, Section, SectionText, SectionImage, SectionTextHeading, SectionTextSubtitle } from "../components/section"

import { StaticImage } from "gatsby-plugin-image"
import { enhanceBlogArticle } from "./help/cullai"
import { ArticleFeed, ArticleFeedItem } from "../components/ghost/articlefeed"
import SEO from "../components/SEO/SEO"
import Banner from "../components/banner"

// markup
const IndexPage = ({ data }) => {
  const { root } = data
  const { articles } = root

  return (

    <Layout>
      <SEO />
      
      <Banner />

      <Hero>

        <SectionText>
          <h1 className="mb-6 text-4xl md:text-5xl lg:text-6xl font-bold">
            <span className="block">
              <span className="inline">Faster <b className="magicull-blue">post-production</b> tools for pro photographers</span>
              {/* <span className="text-blue-700 xl:inline">OptiCull </span> */}
              {/* <span className="xl:inline">to </span> */}
            </span>
            {/* <span className="block">
              <span className="inline">With </span>
              <span className="text-blue-700 xl:inline">AI</span>
            </span> */}
          </h1>
          <SectionTextSubtitle className="text-gray-500">
            We are building tools for you to select, edit and deliver your highest quality photos, <b className="magicull-blue">10x faster</b>.
            <br></br>
            <div className="mt-3 text-base">
              <Link to="/opticull">Check out <b>OptiCull →</b></Link>
            </div>
          </SectionTextSubtitle>
        </SectionText>

        <SectionImage>
          <StaticImage src="../images/hero.png" 
              alt="OptiCull App on iMac and Macbook Pro" title="OptiCull App Screenshot showing different panels and toolbar on iMac and Macbook Pro"
              blurredOptions={{ width: 256 }}>
          </StaticImage>
        </SectionImage>

      </Hero>

      <Section className="bg-blue-600" noborder>

        {/* <div className="pr-4 sm:pr-6 max-w-lg md:pr-0 md:col-span-4 my-auto"> */}
        <SectionText className="md:order-1">
          <h1 className="mb-6 text-4xl md:text-5xl lg:text-6xl font-bold"><span className="text-white">Portrait or Wedding Photographer?</span></h1>
          <p className="text-lg lg:text-xl text-gray-100">Try OptiCull today, the fastest photo culling software for assisted and automated culling.</p>
          {/* <b><Link className="text-white hover:text-gray-100" to="/opticull">OptiCull</Link></b> */}
          <p className="mt-3 text-base">
            <Link className="text-white hover:text-gray-100" to="/opticull">Check out <b>OptiCull →</b></Link>
          </p>
        </SectionText>

        <SectionImage className="md:order-2">
          <Link to="/opticull">
            <StaticImage src="../images/cta-opticull-landing.png" alt="OptiCull App"
              className="rounded md:rounded-lg align-middle border-none">
            </StaticImage>
          </Link>
        </SectionImage>

      </Section>


      <Section nogrid noborder className="related-posts container medium" gridClassName="max-w-3xl mx-auto">
        <h2 className="related-title text-2xl font-bold text-gray-800 mb-4">Latest Blog Articles</h2>
        <ArticleFeed>
          {articles.map(article => {
            enhanceBlogArticle(article)
            return (
              <ArticleFeedItem key={article.id} article={article}></ArticleFeedItem>
            )
          })}
        </ArticleFeed>
      </Section>
    </Layout>

  )
}

export default IndexPage

export const featuredQuery = graphql`
query FeaturedArticlesQuery {
  root: allMdx(
    filter: {
      fields: { source: {eq: "blog"} }
    }
    sort: {fields: frontmatter___date, order: DESC}
    limit: 3
  ) {
    articles: nodes {
      id
      excerpt(pruneLength: 128)
      timeToRead
      frontmatter {
        product
        category
        title
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
}
`