import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ArticleMeta } from "./articlemeta"

export function ArticleFeed({ children }) {
  return (
    <div className="post-feed u-hover-wrapper divide-y-2">
      {children}
    </div>)
}

export function ArticleFeedItem({ article }) {
  return (
    <article className="post tag-billing-account u-hover-item py-8 md:flex relative">
      <Link className="post-link u-permalink absolute -left-5 top-0 -right-5 bottom-0 opacity-5 hover:bg-gray-500" to={article.url}></Link>

      {article.frontmatter.media && (<div className="post-media md:w-52 md:h-0 md:flex-shrink-0 mb-8 md:mb-0 md:mt-4 md:mr-8">
        <div className="u-placeholder same-height rectangle aspect-w-16 aspect-h-9">
          <img className="post-image u-object-fit lazyautosizes lazyloaded absolute w-full h-full object-cover" src="https://images.unsplash.com/photo-1556740720-776b84291f8e?ixlib=rb-1.2.1&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjExNzczfQ" alt="What to Do When Your Card is Declined" />
        </div>
      </div>)}

      <div className="post-wrapper">
        <header className="post-header">
          <h3 className="post-title text-lg font-bold text-gray-800 mb-2">
            {article.frontmatter.title}
          </h3>
        </header>
        <div className="post-excerpt text-gray-500">
          {article.excerpt}
        </div>
        {article.frontmatter.categorySlug !== 'faqs' && (<ArticleMeta article={article}></ArticleMeta>)}
      </div>
    </article>
  )
}

